<template>
	<div>
		<div class="container-fluid">
			<div class="container">
				<div class="row">
					<div class="col">
						<h4 class="text-left title">Overzicht bestellingen</h4>
					</div>
				</div>
			</div>
		</div>

		<div class="container-fluid bg-white">
			<div class="container">
				<div class="row py-4">
					<div class="col">
						<table class="table table-bordered table-hover table-sm">
							<thead>
								<td v-show="false">ID</td>
								<td>Order</td>
								<td>Datum</td>
								<td>Naam</td>
								<td>Bestelde items</td>
								<!-- <td></td> -->
							</thead>
							<tbody>
								<tr v-for="(o, index) in orders" :key="index">
									<td v-show="false">{{ o.id }}</td>
									<td>{{ o.orderId }}</td>
									<td>{{ o.Datum }}</td>
									<td>{{ o.orderInfo }}</td>
									<td>{{ o.items }}</td>
									<!-- <td class="text-center" @click="deleteRow(index, o.id)">
										<i class="fa fa-lg fa-trash"></i>
									</td> -->
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
export default {
	data: () => ({
		orders: [],
	}),

	created() {
		console.clear();
		this.getOrders();
	},

	methods: {
		getOrders: function () {
			axios
				.get('/php/db-sqlite.php/kvstore/key')
				.then((result) => {
					// console.log(result.data);
					this.orders = [];
					for (var i = 0; i < result.data.length; i++) {
						try {
							let item = {};
							item.id = result.data[i].id;
							item.orderId = result.data[i].key;

							let val = JSON.parse(result.data[i].value);
							item.orderInfo = this.printData(val.orderInfo);

							let items = '';
							val.cart.forEach((element) => {
								let obj = {
									Aantal: element.quantity,
									Omschrijving: element.description,
									Bedrag: element.price,
								};
								items += this.printData(obj) + '\n\n';
							});
							item.items = items;
							this.orders.push(item);
						} catch (error) {
							// console.error(error);
							// console.log(i, result.data[i]);
						}
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},

		deleteRow(index, id) {
			console.log(index, id);
			axios
				.delete('/db-sqlite.php/kvstore/' + id)
				.then((result) => {
					console.log(result.data);
					this.orders.splice(index, 1);
				})
				.catch((error) => {
					console.log(error);
				});
		},

		printData(val) {
			return Object.keys(val)
				.map((key) => `${key}: ${val[key]}`)
				.join('\n');
		},
	},
};
</script>

<style>
td {
	white-space: pre;
}
</style>
